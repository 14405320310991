import React from "react";
import { Link } from "gatsby";
import { Button, Box, Heading, Text } from "theme-ui";

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`,
  },
  occupation: {
    mb: 4,
  },
  specialty: {
    color: `text`,
    mb: 4,
  },
};

export default () => (
  <>
    <Heading variant="h1">
      Hi, I'm <Text sx={styles.author}>Agathe Brusset</Text>.
    </Heading>
    <Heading variant="h2" sx={styles.occupation}>
      Product manager, Developer tools, APIs and B2B SaaS
    </Heading>
    <Heading variant="h3" sx={styles.specialty}>
      Making the lives of my users easier, one day at the time
    </Heading>
    <Box variant="buttons.group">
      <Button as={Link} to="/contact">
        Contact
      </Button>
      <Button variant="white" as={Link} to="/about">
        About
      </Button>
    </Box>
  </>
);
